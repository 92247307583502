import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import ECharts from 'vue-echarts' // 引入 vue-echarts
import 'echarts' // 引入 echarts
import router from './router'
import store from './store'
import 'bootstrap'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false
Vue.component('v-chart', ECharts) // 注册 vue-echarts 组件

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// 设置全局导航守卫
router.beforeEach((to, from, next) => {
  // 默认标题
  const defaultTitle = '向上攀登'
  // 获取路由的 meta.title 并拼接项目名称
  document.title = to.meta.title ? `${to.meta.title} - ${defaultTitle}` : defaultTitle
  next()
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../components/register.vue'),
  },
  {
    path: '/price/stock/:ts_code', // 股票行情
    name: 'PriceStockNew',
    component: () => import('../components/price/stock.vue'),
  },
  {
    path: '/',
    component: () => import('../components/home/index.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('../components/home/home.vue'),
      },
      {
        path: 'test/test', // test 测试
        name: 'TestTest',
        component: () => import('../components/test/test.vue'),
      },
      {
        path: 'user/list', // 用户列表
        name: 'UserList',
        component: () => import('../views/user/list.vue'),
      },
      {
        path: 'user/remindSetting', // 用户提醒设置
        name: 'RemindSetting',
        component: () => import('../views/user/remindSetting.vue'),
      },
      {
        path: 'wallets/list', // 钱包
        name: 'WalletsList',
        component: () => import('../components/wallets/list.vue'),
      },
      {
        path: 'index/groupList', // 目录组管理
        name: 'IndexGroupList',
        component: () => import('../components/index/groupList.vue'),
      },
      {
        path: 'index/list', // 目录管理
        name: 'IndexList',
        component: () => import('../components/index/list.vue'),
      },
      {
        path: 'price/index', // 指数行情
        name: 'PriceIndex',
        component: () => import('../components/price/index.vue'),
      },
      {
        path: 'price/stock', // 股票行情
        name: 'PriceStock',
        component: () => import('../components/price/stock.vue'),
      },
      {
        path: 'price/pick', // 我的自选
        name: 'PricePick',
        component: () => import('../views/pick/pick.vue'),
      },
      {
        path: 'analyze/group/list', // 策略组
        name: 'AnalyzeGroupList',
        component: () => import('../views/analyze/group_list.vue'),
      },
      {
        path: 'price/technical', // 技术形态选股
        name: 'PriceTechnical',
        component: () => import('../views/technical/list.vue'),
      },
      {
        path: 'price/alert', // 短线提醒买卖点
        name: 'PriceAlert',
        component: () => import('../views/alert/list.vue'),
      },
      {
        path: 'price/top', // 每日龙虎榜
        name: 'PriceTop',
        component: () => import('../views/top/list.vue'),
      },
      {
        path: 'price/pop', // 每日人气榜
        name: 'PricePop',
        component: () => import('../views/pop/list.vue'),
      },
      {
        path: 'price/hot', // 每日热榜
        name: 'Pricehot',
        component: () => import('../views/hot/list.vue'),
      },
      {
        path: 'price/subscrip', // 订阅信息
        name: 'PriceSubscrip',
        component: () => import('../components/subscrip/list.vue'),
      },
      {
        path: 'price/radar', // 动向雷达
        name: 'PriceRadar',
        component: () => import('../views/radar/list.vue'),
      },
      {
        path: 'log/show', // 日志管理 日志展示
        name: 'LogShow',
        component: () => import('../components/log/list.vue'),
      },
      {
        path: 'making/effect', // 赚钱效应指数
        name: 'MakingEffect',
        component: () => import('../views/making/effect.vue'),
      },
      {
        path: 'log/executedList/:menu/:tableName/:mode', // 日志管理 执行情况日志
        name: 'LogExecutedList',
        component: () => import('../components/log/executedList.vue'),
      },
    ],
  },
]

// 保存原有的push方法
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location, resolve, reject) {
  // 如果用户传递过来了成功失败回调
  // 调用原有push方法
  if (resolve && reject) {
    originalPush.call(this, location, resolve, reject)
  } else {
    // 用户没有传递成功失败回调，我们自动加上
    originalPush.call(
      this,
      location,
      () => {},
      () => {},
    )
  }
}

// 保存原有的replace方法
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    originalReplace.call(this, location, resolve, reject)
  } else {
    originalReplace.call(
      this,
      location,
      () => {},
      () => {},
    )
  }
}

const router = new VueRouter({
  // mode: "history",
  routes,
})

export default router

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// 应用初始状态
const state = {
  token: null,
  user: null,
  collapsed: false,
}

// 定义所需的 mutations
const mutations = {
  LOGIN: (state, data) => {
    state.token = data.token
    state.user = data.user
  },
  LOGOUT: (state) => {
    state.token = null
    state.user = null
  },
  collapsedClose: (state) => {
    state.collapsed = true
  },
  collapsedOpen: (state) => {
    state.collapsed = false
  },
  collapsedSwitch: (state, data) => {
    state.collapsed = !data.data
  },
}

export default new Vuex.Store({
  state: state,
  getters: {},
  mutations: mutations,
  actions: {},
  modules: {},
})
